<section class="announcement">
	<div class="overflow-wrapper">
		<h1 class="title heading-big">
			<span>Sreetam</span>
			<span aria-label="&" class="ampersand-hidden" />
			<span>Areena</span>
		</h1>
	</div>
	<span class="ampersand" aria-hidden="true">&</span>
	<div class="overflow-wrapper">
		<p class="description-main">
			<s>are getting</s> got married.
		</p>
	</div>
</section>

<style lang="postcss">
	section {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1;

		--timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
	}

	.announcement {
		position: relative;
		min-height: calc(100vh - var(--header-height));
	}

	.overflow-wrapper {
		overflow: hidden;
	}

	.heading-big {
		width: 100%;
		font-size: 120px;
		padding: 0;
		margin: 200px 0 0;
	}

	.title,
	.description-main {
		display: block;
		position: relative;
		z-index: 3;
	}

	.title {
		animation: slide-up 1500ms var(--timing-function);
		display: flex;
		column-gap: 150px;

		transition-duration: 300ms;
		transition-timing-function: var(--timing-function);
		transition-property: row-gap, margin-top;

		@media (max-width: 1085px) {
			font-size: 3.5rem;
			margin-top: 100px;
			flex-direction: column;
			row-gap: 100px;
		}
		@media (max-width: 600px) {
			font-size: 80px;
			margin-top: 65px;
			row-gap: 80px;
		}
	}

	.ampersand {
		position: absolute;
		top: 50px;
		font-size: 420px;
		color: var(--tertiary-color);
		opacity: 0.7;
		z-index: 0;
		left: 55%;
		transform: translate(-50%, 0);

		@media (max-width: 1085px) {
			left: 50%;
			top: 100px;
			font-size: 300px;
		}
		@media (max-width: 600px) {
			top: 50px;
		}
	}

	.ampersand-hidden {
		display: contents;
	}

	.description-main {
		font-size: 1.5rem;
		margin: 50px 0 0;
		animation: slide-up-delayed 1800ms var(--timing-function);
		transition-duration: 300ms;
		transition-timing-function: var(--timing-function);
		transition-property: font-size, margin-top;

		@media (max-width: 1085px) {
			font-size: 1.3rem;
			margin-top: 30px;
		}
		@media (max-width: 600px) {
			font-size: 1.1rem;
			margin-top: 20px;
		}
	}

	@keyframes slide-up {
		0% {
			transform: translateY(100%);
		}
		20% {
			transform: translateY(100%);
		}
		100% {
			transform: translateY(0%);
		}
	}
	@keyframes slide-up-delayed {
		0% {
			transform: translateY(100%);
		}
		30% {
			transform: translateY(100%);
		}
		100% {
			transform: translateY(0%);
		}
	}
</style>
