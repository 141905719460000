<iframe
	width="900"
	height="600"
	src="https://www.youtube-nocookie.com/embed/nhkvcdBqRaE?si=oDSBl6hAiXNaudiF"
	title="YouTube: Ready Player Two - Sreetam & Areena - 17th January, 2024"
	frameborder="0"
	allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
	referrerpolicy="strict-origin-when-cross-origin"
	allowfullscreen
></iframe>
