<script lang="ts">
	import { onMount } from "svelte";
	import { register } from "swiper/element/bundle";
	import type { Swiper as SwiperType } from "swiper/types";

	import WeddingVideoEmbed from "./WeddingVideoEmbed.svelte";

	register();

	import "swiper/element/css/a11y";
	import "swiper/element/css/keyboard";
	import "swiper/element/css/navigation";

	let swiper: SwiperType;
	let swiperElement;
	let stories: HTMLElement;
	let observer: IntersectionObserver | null = null;
	let slidesLoopPadding: number;

	let mql: MediaQueryList;
	let isMounted = false;
	let isMobileLayout = false;

	const slides = [
		/* 0 */ "class-8th.jpg",
		/* 1 */ "2014-allahabad.jpg",
		/* 2 */ "2018-jun-muli.jpg",
		/* 3 */ "pizza-bakery.jpg",
		/* 4 */ "mp4/2019-mar.mp4",
		/* 5 */ "2020-feb-parantha.jpg",
		/* 6 */ "mp4/2020-feb.mp4",
		/* 7 */ "2022-jan-date-night.jpg",
		/* 8 */ "2022-jan-30.jpg",
		/* 9 */ "mp4/2022-new-york.mp4",
		/* 10 */ "2022-feb-1-glasses.jpg",
		/* 11 */ "2022-feb-14-trufa-pizza.jpg",
		/* 12 */ "new-york.jpg",
		/* 13 */ "2022-feb-14-sunshine.jpg",
		/* 14 */ "2022-feb-15-campus-night.jpg",
		/* 15 */ "2022-feb-16-airport.jpg",
		/* 16 */ "2023-may-10-day.jpeg",
		/* 17 */ "2023-may-10-night.jpeg",
	];
	const timestampIDs = {
		"2009-july": { slideIndex: 0 },
		"2014-june": { slideIndex: 1 },
		"2017-july": { slideIndex: 1 },
		"2018-june": { slideIndex: 2 },
		"2018-august": { slideIndex: 2 },
		"2018-november": { slideIndex: 3 },
		"2019-june": { slideIndex: 4 },
		"2019-november": { slideIndex: 5 },
		"2020-march": { slideIndex: 5 },
		"2020-july": { slideIndex: 6 },
		"2020-november": { slideIndex: 7 },
		"2021-july": { slideIndex: 8 },
		"2021-november": { slideIndex: 9 },
		"2022-january": { slideIndex: 11 },
		"2022-august": { slideIndex: 12 },
		"2022-november": { slideIndex: 13 },
		"2022-december": { slideIndex: 14 },
		"2023-january": { slideIndex: 15 },
		"2023-may": { slideIndex: 17 },
	};

	const wedding_slides = [
		{ video_embed: WeddingVideoEmbed },
		{ image: "haldi.jpg" },
		{ image: "sangeet.jpg" },
		{ image: "sangeet-all.jpg" },
		{ image: "wedding-entrance.jpg" },
		{ image: "wedding-stairs.jpg" },
		{ image: "wedding-reception.jpg" },
		{ image: "wedding-bnw.jpg" },
		{ image: "wedding-main.jpg" },
		{ image: "wedding-ritual.jpg" },
	];

	type TimestampID = keyof typeof timestampIDs;
	const intersectingElementsIDs = new Set<TimestampID>();

	type ElementsArrayType = {
		[key in TimestampID]: { element: null | HTMLElement; intersecting?: boolean };
	};
	const elements = Object.keys(timestampIDs).reduce<ElementsArrayType>((obj, id) => {
		obj[id as TimestampID] = {
			element: null,
			intersecting: undefined,
		};

		return obj;
	}, {} as ElementsArrayType);

	function initSwiper() {
		swiperElement = document.querySelector("swiper-container");
		// @ts-expect-error swiper
		swiper = swiperElement?.swiper;
		slidesLoopPadding = swiper.activeIndex;
	}

	function getSetFirstValue<T extends undefined | string>(set: Set<T>): T | undefined {
		const [value] = set;
		return value;
	}

	function handleIntersecting(entry: IntersectionObserverEntry) {
		const articleID = entry.target.id as TimestampID;

		if (entry.isIntersecting) {
			if (!intersectingElementsIDs.has(articleID)) {
				intersectingElementsIDs.add(articleID);
			}
		} else {
			if (intersectingElementsIDs.has(articleID)) {
				intersectingElementsIDs.delete(articleID);
				const oldestSlideID = getSetFirstValue(intersectingElementsIDs);

				if (!isMobileLayout && typeof oldestSlideID !== "undefined") {
					swiper.slideTo(timestampIDs[oldestSlideID].slideIndex + slidesLoopPadding);
				}
			}
		}
	}

	function initIntersectionObserver() {
		observer = new IntersectionObserver(
			(entries) => {
				entries.forEach(handleIntersecting);
			},
			{ threshold: [0.5, 0.6, 0.7], rootMargin: "-100px" },
		);

		if (typeof stories?.children !== "undefined") {
			[...stories.children].map((storyArticle) => {
				observer?.observe(storyArticle);
			});
		}
	}

	function mqlListener(event: MediaQueryListEvent) {
		isMobileLayout = event.matches;
	}
	function removeActiveListener() {
		if (mql) {
			mql.removeEventListener("change", mqlListener);
		}
	}

	onMount(() => {
		initSwiper();
		initIntersectionObserver();
		isMounted = true;

		return () => {
			if (observer) {
				observer.disconnect();
				observer = null;
			}
			removeActiveListener();
		};
	});

	$: {
		if (isMounted) {
			removeActiveListener();

			mql = window.matchMedia("(max-width: 960px)");
			mql.addEventListener("change", mqlListener);
			isMobileLayout = mql.matches;
		}
	}
</script>

<section id="our-story">
	<h2 class="section-heading">Our story</h2>

	<div class="content-wrapper">
		<div class="content-story" bind:this={stories}>
			<article id="2009-july">
				<h3>2009</h3>
				<p>
					Sreetam joins APS Lucknow in 8th grade, where he meets Areena — yep, they've known each
					other for over 14 years!
				</p>
				<p>
					They quickly become friends, and Areena teases Sreetam for all sorts of things. He
					<em>tries</em> to be cool, while she actually is.
				</p>
				<p>
					Over the next 2 years, they become close friends and stay in touch even after Areena moves
					out of Lucknow.
				</p>
			</article>

			<article id="2014-june">
				<h3>2014</h3>
				<p>
					They get a chance to meet up in Allahabad for literally just a couple of minutes before
					they both go their separate ways.
				</p>
				<p>
					Areena goes to the US to pursue her degree in Ohio, while Sreetam is off to Warangal to
					pursue his. They keep in touch despite the time zone difference.
				</p>
				<p>He gets to make fun of her American-ish accent.</p>
			</article>

			<article id="2017-july">
				<h3>2017</h3>
				<p>
					Areena gets her first job and moves to Idaho! Sreetam completes his internship and accepts
					a full-time offer to start in Bengaluru in July 2018.
				</p>
			</article>

			<article id="2018-june">
				<h3 class:intersecting={elements["2018-june"].intersecting}>June 2018</h3>
				<p>
					Areena moves back to India and meets Sreetam on his birthday in Delhi with another Lucknow
					mutual best friend, Amulya.
				</p>
				<p>
					While getting lunch together, she receives an email: she's got a job offer in Bengaluru.
				</p>
				<p>
					Sreetam jokes that he's her lucky charm, and this was the universe giving her a signal.
				</p>
				<p>Oh boy, they had no idea.</p>
			</article>

			<article id="2018-august">
				<h3>August 2018</h3>
				<p>
					Areena moves to Bengaluru! They meet up and sparks fly immediately. Over the next couple
					of months, they become closer than ever and really get to know each other.
				</p>
			</article>

			<article id="2018-november">
				<h3>November 2018</h3>
				<p>
					They finally start dating! They are each other's best friends. They explore everything
					that Bengaluru offers, and try out so, so many things. Areena is nervous things would not
					work out after Sreetam gets to <em>really</em> know her — "I think you'll break up with me
					in 2 months".
				</p>
				<p>Sreetam is determined to prove her wrong, and now they're getting married.</p>
			</article>

			<article id="2019-june">
				<h3>June 2019</h3>
				<p>
					Sreetam officially tells his parents about Areena. He's been warming them up to her, and
					it works — they like her, <em>a lot</em>.
				</p>
				<p>
					They go to Kapoor's Cafe and Sreetam learns what a <em>good</em> Punjabi parantha feels
					like, and what the right amount of butter is — <em>a lot</em>.
				</p>
			</article>

			<article id="2019-november">
				<h3>November 2019</h3>
				<p>
					Sreetam (un)officially "proposes" to Areena, with a ring off of Amazon. It's a promise
					ring, they <em>really</em> want to be married to each other.
				</p>
				<p>They express their love in their own mysterious ways.</p>
			</article>

			<article id="2020-march">
				<h3>March 2020</h3>
				<p>
					COVID-19 lockdowns begin, Sreetam and Areena consider themselves lucky to be together
					through it.
				</p>
				<p>She teaches him how to cook, and he realises he really likes cooking for her.</p>
				<p>She realises she really likes teaching him how to cook.</p>
			</article>

			<article id="2020-july">
				<h3>July — September 2020</h3>
				<p>Areena goes to her parents' for two months, it's the longest they've been apart.</p>
			</article>

			<article id="2020-november">
				<h3>November 2020</h3>
				<p>Sreetam changes jobs and they both move back to their parents'.</p>
				<p>
					On the last night, they go to The Pizza Bakery and eat their stuffed garlic bread and
					tiramisu.
				</p>
				<p>They leave together — sharing a pizza at Alta Vita, at the Bengaluru airport.</p>
				<p>Sreetam keeps the paper coaster as a keepsake.</p>
			</article>

			<article id="2021-july">
				<h3>July 2021</h3>
				<p>
					Areena comes back to Bengaluru to drop off her office things — she's leaving to pursue her
					Master's degree! Sreetam and their friends also fly down to meet her before she leaves.
				</p>
				<p>
					Sreetam goes to the airport to pick Areena up, and finds out he's getting promoted on the
					way. He knows she's his lucky charm.
				</p>
				<p>
					On the last night, they go to The Pizza Bakery and eat their stuffed garlic bread and
					tiramisu.
				</p>
				<p>They leave together — sharing a calzone at Alta Vita, at the Bengaluru airport.</p>
				<p>Sreetam keeps the paper coaster as a keepsake.</p>
			</article>

			<article id="2021-november">
				<h3>November 2021</h3>
				<p>Sreetam is able to book an appointment at the US consulate to get a visitor's visa.</p>
			</article>

			<article id="2022-january">
				<h3>January — February 2022</h3>
				<p>
					Sreetam travels to Kolkata for his appointment, and gets his visa. Then books his flight
					to the US in a week and collects his passport in Delhi.
				</p>
				<p>
					He flies to New York — it's his first ever international trip. They explore everything
					that New York offers, and try out so, so many things.
				</p>
			</article>

			<article id="2022-august">
				<h3>August 2022</h3>
				<p>
					Areena graduates and moves to Tennessee and sets up her new place with her new job. Her
					parents help her move, and talk to her about her marriage.
				</p>
				<p>On the other side of the world, Sreetam feels butterflies in his stomach once again.</p>
			</article>

			<article id="2022-november">
				<h3>November 2022</h3>
				<p>Sreetam's parents fly to Delhi to meet Areena's family.</p>
				<p>It. is. happening.</p>
			</article>

			<article id="2022-december">
				<h3>31<sup>st</sup> December 2022</h3>
				<p>
					Areena's parents tell her they're going to talk to Sreetam's parents the next day and
					confirm dates.
				</p>
				<p>Areena tells this to Sreetam at midnight.</p>
			</article>

			<article id="2023-january">
				<h3>1<sup>st</sup> January 2023</h3>
				<p>
					Their parents talk and confirm a date when their extended families will meet and they'll
					get engaged.
				</p>
			</article>
			<article id="2023-may">
				<h3>10<sup>th</sup> May 2023</h3>
				<p>Sreetam and Areena get engaged, surrounded by family and close friends.</p>
			</article>
		</div>

		<div class="content-slides">
			<swiper-container
				slides-per-view="auto"
				space-between="30"
				speed="600"
				grab-cursor="true"
				a11y="true"
				keyboard="true"
				navigation="true"
				loop="true"
			>
				{#each slides as mediaFile}
					<swiper-slide>
						<article class="slide-actual">
							{#if mediaFile.split(".")[1] === "mp4"}
								<video
									src={`/images/colmar/${mediaFile}`}
									width="500"
									height="600"
									autoplay
									loop
									muted
									playsinline
								/>
							{:else}
								<img
									src={`/images/colmar/${mediaFile}`}
									alt="Sreetam and Areena"
									height="600"
									loading="lazy"
								/>
							{/if}
						</article>
					</swiper-slide>
				{/each}
			</swiper-container>
		</div>
	</div>

	<div id="wedding">
		<article id="2024-january">
			<h3>17<sup>th</sup> January 2024</h3>
			<p>
				After fourteen years of being friends, close buddies for six, dating for five and being
				obsessed with each other all along,
			</p>
			<p>Sreetam and Areena get married. :')</p>
		</article>

		<div class="wedding-slides">
			<swiper-container
				slides-per-view="auto"
				space-between="30"
				speed="600"
				grab-cursor="true"
				a11y="true"
				keyboard="true"
				navigation="true"
				loop="true"
			>
				{#each wedding_slides as media_obj}
					<swiper-slide>
						<article class="slide-actual">
							{#if media_obj.video_embed}
								<svelte:component this={media_obj.video_embed} />
							{:else}
								<img
									src={`/images/wedding/${media_obj.image}`}
									alt="Sreetam and Areena"
									height="600"
									loading="lazy"
								/>
							{/if}
						</article>
					</swiper-slide>
				{/each}
			</swiper-container>
		</div>
	</div>

	<div class="quote-container">
		<p>
			<span class="large-quote">&ldquo;</span>When you're a kid you assume
			<br />
			your parents are soulmates.
			<br />
			<br />
			Our kids are going to be right about that.<span class="large-quote">&rdquo;</span>
			<br />
			<br />
			<span class="quote-credits">
				— <a href="https://youtu.be/DpZnZGIuUBQ?t=129" target="_blank">The Office</a>
			</span>
		</p>
	</div>

	<div id="contact-us">
		<h3>Contact us</h3>

		<details>
			<summary>Wanna send us some love or a message?</summary>

			<iframe
				src="https://docs.google.com/forms/d/e/1FAIpQLSf9AzMz0woHOW7KyIV4EryTyzndwFnoElY7l9Aq3voWb28zuA/viewform?embedded=true"
				width="100%"
				height="950"
				frameborder="0"
				marginheight="0"
				marginwidth="0"
				title="Message for Sreetam and Areena">Loading…</iframe
			>
		</details>
	</div>
</section>

<style lang="postcss">
	.content-wrapper {
		--swiper-navigation-color: var(--secondary-color);
		--slide-height: 600px;

		display: flex;
		gap: 50px;
		position: relative;
		padding-bottom: 100px;

		@media (max-width: 960px) {
			flex-direction: column;
		}
	}

	.content-story {
		flex-grow: 1;

		h3 {
			margin: 0;
		}

		p {
			font-size: 0.9rem;
			font-family:
				"EB Garamond",
				-apple-system,
				serif;

			@media (max-width: 400px) {
				font-size: 1rem;
			}

			&:first-child {
				margin-top: 30px;
			}
		}

		article {
			&:not(:last-of-type) {
				padding-bottom: 150px;
			}

			&:last-child {
				min-height: var(--slide-height);

				@media (max-width: 1085px) {
					min-height: unset;
				}

				p {
					margin-bottom: 0;
				}
			}
		}
	}
	.slide-actual {
		margin: 0;
		padding: 0;
		background-color: black;
		height: var(--slide-height);
		border-radius: 25px;
		width: 100%;
		overflow: hidden;

		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 60px;
	}

	.content-slides {
		position: sticky;
		top: calc(var(--header-height) + 25px);
		align-self: flex-start;

		swiper-container {
			max-width: min(450px, calc(100vw - 30px));
		}
		.slide-actual {
			max-width: min(450px, calc(100vw - 30px));
		}
	}

	swiper-container {
		max-width: min(450px, calc(100vw - 30px));
		border-radius: 25px;
		height: var(--slide-height);
		overflow: hidden;
	}

	swiper-slide {
		display: flex;
		justify-content: center;
	}

	#wedding {
		--swiper-navigation-color: rgb(0, 255, 255);

		.wedding-slides {
			swiper-container,
			.slide-actual {
				max-width: min(900px, calc(100vw - 30px));
			}
		}

		p {
			font-family:
				"EB Garamond",
				-apple-system,
				serif;
		}
	}

	.quote-container {
		background-color: var(--primary-color);
		padding: 300px 0 100px;

		display: flex;
		justify-content: center;
	}
	.quote-container p {
		font-family: "Magilio";
		font-size: 1.8rem;
		letter-spacing: -1px;
		line-height: 1;
		margin: 0;
	}

	.large-quote::selection {
		background: var(--text-color);
		color: var(--pure-white);
	}

	.quote-credits {
		display: flex;
		justify-content: end;

		> a {
			text-decoration: underline;

			&::after {
				content: "🡥";
				display: inline-block;
				padding-left: 0.25rem;
				color: transparent;
				font-size: 0.7em;
			}

			&:hover::after {
				color: black;
			}
		}
	}

	#contact-us {
		display: flex;
		justify-content: center;
		flex-direction: column;

		padding: 0;

		> details {
			width: 100%;
			font-family:
				"EB Garamond",
				-apple-system,
				serif;
		}
	}
</style>
